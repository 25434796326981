.rc-slider-rail {
  height: 10px !important;

  background-color: rgba(128, 128, 255, 0.15) !important;
}

.rc-slider-track {
  height: 10px !important;
  background-color: var(--color-primary-700) !important;
}

.rc-slider-handle {
  z-index: 1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) !important;
  opacity: 1 !important;
  width: 25px !important;
  height: 25px !important;
  margin-top: -7.5px !important;
  border-radius: 6px !important;
  background-color: var(--color-primary-700) !important;
  border: solid 1px var(--color-edge) !important;
  &-dragging {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) !important;
  }
}

.green {
  .rc-slider-rail {
    background-color: rgba(45, 235, 197, 0.15) !important;
  }

  .rc-slider-handle,
  .rc-slider-track {
    background-color: #2debc5 !important;
  }
}

.yellow {
  .rc-slider-rail {
    background-color: rgba(240, 185, 11, 0.15) !important;
  }

  .rc-slider-handle,
  .rc-slider-track {
    background-color: #ffd759 !important;
  }
}
