@layer utilities {
  .tg-title-h1 {
    font-size: 3.75rem; // 60px
    line-height: 5rem; // 80px
    font-weight: 700;
  }

  .tg-title-h2 {
    font-size: 2.25rem; // 36px
    line-height: 3.125rem; // 50px
    font-weight: 700;
  }

  .tg-title-h3 {
    font-size: 2rem; // 32px
    line-height: 2.5rem; // 40px
    font-weight: 700;
  }

  .tg-title-h4 {
    font-size: 1.75rem; // 28px
    line-height: 2.25rem; // 36px
    font-weight: 700;
  }

  .tg-title-h5 {
    font-size: 1.5rem; // 22px
    line-height: 1.875rem; // 30px
    font-weight: 700;
  }

  .tg-title-h6 {
    font-size: 1.25rem; // 20px
    line-height: 1.875rem; // 30px
    font-weight: 700;
  }

  .tg-headline {
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px
    font-weight: 700;
  }

  .tg-body {
    font-size: 1.125rem; // 18px
    line-height: 1.875rem; // 30px
    font-weight: normal;
  }

  .tg-body-bold {
    font-size: 1.125rem; // 18px
    line-height: 1.875rem; // 30px
    font-weight: 600;
  }

  .tg-main-bold {
    font-size: 1rem; // 16px
    line-height: 1.375rem; // 22px
    font-weight: 600;
  }

  .tg-main {
    font-size: 1rem; // 16px
    line-height: 1.375rem; // 22px
    font-weight: normal;
  }

  .tg-subhead {
    font-size: 0.9375rem; // 15px
    line-height: 1.375rem; // 22px
    font-weight: normal;
  }

  .tg-content {
    font-size: 0.875rem; // 14px
    line-height: 1.25rem; // 20px
    font-weight: normal;
  }

  .tg-content-bold {
    font-size: 0.875rem; // 14px
    line-height: 1.25rem; // 20px
    font-weight: 600;
  }

  .tg-caption {
    font-size: 0.75rem; // 12px
    line-height: 1rem; // 16px
    font-weight: normal;
  }

  .tg-caption-bold {
    font-size: 0.75rem; // 12px
    line-height: 1rem; // 16px
    font-weight: 600;
  }

  .tg-caption-sm {
    font-size: 0.6rem; // 9.6px
    line-height: 0.8rem; // 12.8px
    font-weight: normal;
  }
}
