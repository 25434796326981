$bnpl-font-family: "bnpl" !default;
$bnpl-font-path: "~styles/fonts/bnpl" !default;

@font-face {
  font-family: "#{$bnpl-font-family}";
  src: url("#{$bnpl-font-path}/#{$bnpl-font-family}.eot?chafhu");
  src: url("#{$bnpl-font-path}/#{$bnpl-font-family}.eot?chafhu#iefix") format("embedded-opentype"),
    url("#{$bnpl-font-path}/#{$bnpl-font-family}.ttf?chafhu") format("truetype"),
    url("#{$bnpl-font-path}/#{$bnpl-font-family}.woff?chafhu") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
