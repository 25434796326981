html {
  --color-overlay-bg: rgba(80, 85, 104, 0.3);
}

html {
  --color-title: #002237;
  --color-text: #657795;

  --color-primary-700: #5454d4;
  --color-primary-600: #6565d8;
  --color-primary-500: #7676dd;
  --color-primary-400: #8787e1;
  --color-primary-300: #9898e5;
  --color-primary-200: #aaaaea;
  --color-primary-100: #bbbbee;

  --color-secondary: #ebeff6;
  --color-edge: #dae3f3;

  --color-yellow-700: #fcb803;
  --color-yellow-600: #fcbf1c;
  --color-yellow-500: #fdc635;
  --color-yellow-400: #fdcd4f;
  --color-yellow-300: #fdd468;
  --color-yellow-200: #fedc81;
  --color-yellow-100: #fee39a;

  --color-black-700: #000000;
  --color-black-600: #141414;
  --color-black-500: #212529;
  --color-black-450: #1d2127;
  --color-black-400: #002237;
  --color-black-300: #868e96;
  --color-black-200: #adb5bd;
  --color-black-100: #304b66;

  --color-gray-50: #ffffff;
  --color-gray-100: #e8e8e9;
  --color-gray-200: #d0d1d2;
  --color-gray-300: #b9babc;
  --color-gray-400: #a1a3a5;
  --color-gray-500: #8a8d8f;
  --color-gray-650: #727678;
  --color-gray-600: #5b5f62;
  --color-gray-700: #43484b;
  --color-gray-800: #2c3135;
  --color-gray-900: #141a1e;

  --color-red-700: #ff5959;
  --color-red-600: #ff6a6a;
  --color-red-500: #ff7a7a;
  --color-red-400: #ff8b8b;
  --color-red-300: #ff9b9b;
  --color-red-200: #ffacac;
  --color-red-100: #ffbdbd;

  --color-green-700: #3fb68b;
  --color-green-600: #52bd97;
  --color-green-500: #65c5a2;
  --color-green-400: #79ccae;
  --color-green-300: #8cd3b9;
  --color-green-200: #9fdbc5;
  --color-green-100: #b2e2d1;

  --color-brown-400: #92400e;

  --card-bg: white;
  --card-text: #657795;
  --color-divider: var(--color-gray-200);
}

html.ui-dark {
  --color-title: #ffffff;
  --color-text: #c7d2da;

  --color-edge: #2c373e;

  --card-bg: var(--color-gray-900);
  --color-divider: var(--color-gray-700);
}

$break-points: (
  "sm": 640px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1280px,
  "2xl": 1536px
);

$font-family: "Sora", system-ui, -apple-system,
  /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
