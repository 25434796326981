@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700;800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: var(--color-text);
  background-color: var(--color-gray-50);

  html.ui-dark & {
    background-color: var(--color-gray-900);
  }
}

html.stop-scrolling body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: var(--color-blue-600);
  }
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 5rem white inset;
  html.ui-dark & {
    caret-color: white;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 5rem var(--color-black-600) inset;
  }
}

/* Remove arrows from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* scrollbar style */
/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: var(--color-black-200);
  html.ui-dark & {
    background: var(--color-gray-700);
  }
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-edge);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-700);
}

.text-backdrop {
  position: relative;
  align-self: flex-start;
  &::before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: -0.25rem;
    z-index: -1;
    height: 50%;
    left: 0;
  }
}

html:not(.ui-dark) {
  .text-backdrop::before {
    background: var(--color-secondary);
  }
}

html.ui-dark {
  .text-backdrop::before {
    background: var(--color-gray-500);
  }
}
